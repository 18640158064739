@charset "UTF-8";

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: #333333;
}


:root {
    --frame-gap: 16.18mm;
}

body {
    /* font-family: Arial, Arial, sans-serif; */
    font-size: 10pt;
    color: #333333;
    line-height: 1.5em;
}

.logo {
    font-size: xx-large;
    display: flex;
    align-items: center;
}

.wrap {
    display: grid;
    justify-content: center;
    padding: 7vw;
}

nav {
    display: flex;
    justify-content: space-between;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.header img {
    width: 76pt;
}

.header .brand {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.brand a {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

header .brand-description {
    font-size: 9pt;
    color: rgba(0, 0, 0, 0.7);
}

.bold {
    font-weight: 600;
    color: black;
}

.invoice-paper {
    position: relative;
    box-shadow: 8px 14px 80px 0 rgba(0, 0, 0, 0.16);
    height: 297mm;
    width: 210mm;
    padding: 16.18mm;
    display: flex;
    flex-direction: column;
}

.address-wrapper {
    margin-top: 5em;
    display: flex;
    justify-content: space-between;
}

.personal-data {
    display: flex;
    gap: 2em;
}

.date-wrapper {
    margin-top: 3em;
    display: flex;
    justify-content: flex-end;
}

.invoice-wrapper {
    margin-top: 3em;
    font-size: 12pt;
}

.invoice-number {
    font-weight: bold;
}

.invoice-number span {
    font-size: 12pt;
}

.running-invoice-number {
    display: inline-block;
}

.intro-text {
    margin-top: 2em;
}

.intro-text div {
    display: inline-block;
}

.head-row {
    font-size: 12pt;
    margin-top: 1em;
    background: black;
    color: white;
    padding: 0.6em 1em;
    display: flex;
    justify-content: space-between;
}

.service-row {
    position: relative;
    border-bottom: 1px lightgrey solid;
    padding: 0.6em 1em;
    display: flex;
    justify-content: space-between;
}

.hidden {
    visibility: hidden;
}

.currency {
    display: flex;
}

.result-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom: 4em;
}

.result-row {
    position: relative;
    padding: 0.6em 1em 0;
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
}

.description {
    text-align: right;
    width: 9em;
}

.payment-text {
    max-width: 85%;
}

.payment {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.payment-message strong {
    display: flex;
}

.message-text {
    margin-top: 0;
    margin-bottom: 0.6em;
}

.payment-iban {
    display: flex;
    align-items: center;
    gap: 2em;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
}

.qr-code {
    display: flex;
}

.payment-text {
    padding-bottom: 1.2em;
}

.foot-note {
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
}

.page-number strong {
    padding-left: 0.3em;
    padding-right: 0.3em;
}

.foot-note {
    font-size: 9pt;
    color: #595959;
}

.foot-note .invoice-number-clone {
    font-weight: normal;
    font-size: 0;
}

.foot-note .invoice-number-clone span {
    font-size: 9pt;
}

.foot-note span {
    font-weight: bold;
    color: black;
}

.foot-note .invoice-title {
    color: #595959;
    font-weight: normal;
}

.address-dropdown {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(100%, -50%);
    cursor: pointer;
    opacity: 0;
    transition: 0.3s ease-in-out;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.12);
    border: 0;
    height: 2.4em;
    width: 2.4em;
}

.address-dropdown select {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.address-dropdown:after {
    content: "";
    display: block;
    position: absolute;
    pointer-events: none;
    width: 5px;
    height: 5px;
    border-top: 2px black solid;
    border-right: 2px black solid;
    transform: rotate(135deg);
}

.address-customer {
    position: relative;
    max-width: 35ch;
}

.multiply-row:hover .row-button,
.address-wrapper:hover .address-dropdown {
    opacity: 1;
}

.page-buttons {
    z-index: 10;
    display: flex;
    padding-top: 1rem;
    justify-self: center;
    align-self: center;
    place-items: center;
    gap: 1em;
}

.branch-button:hover .leaf-button {
    transition: 0.3s ease-in-out;
    width: 5rem;
    opacity: 1;
    display: block;
}

.branch-button>.leaf-button {
    opacity: 0;
    display: none;
}

.leaf-button {
    min-width: 2.4rem;
    padding: 0.4rem 1.2rem;
}

button:hover {
    transform: scale(1.06);
    box-shadow: 5px 5px 30px 5px rgba(0, 0, 0, 0.22);
}

.page-buttons>button,
.leaf-button {
    cursor: pointer;
    opacity: 0;
    transition: 0.3s ease-in-out;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.12);
    border: 0;
    font-size: 1.2rem;
    min-height: 2.4rem;
    min-width: 2.4rem;
}

.page-buttons:hover .leaf-button {
    opacity: 1;
}

.row-buttons {
    display: flex;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    z-index: 100;
    gap: 0.6rem;
}

.row-button {
    cursor: pointer;
    opacity: 0;
    transition: 0.3s ease-in-out;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.12);
    border: 0;
    font-size: 1.2rem;
    height: 2.4rem;
    width: 2.4rem;
}

.golden-wrapper {
    position: relative;
    z-index: -1;
    width: 100%;
}

.golden {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transform: scaleX(-1);
}

.shadow-costs {
    position: absolute;
    color: #9a9a9a;
    right: calc(var(--frame-gap)* -2);
    padding-left: calc(var(--frame-gap) / 2);
    padding-right: calc(var(--frame-gap) * 3);
    transform: translateX(100%);
    border-bottom: #d4d4d4 1px solid;
}

.is-error {
    background-color: red;
    color: white;
    border-radius: 4px;
    padding: 0 4px;
}




@media print {
    body {
        -webkit-print-color-adjust: exact;
    }

    nav {
        display: none;
    }

    .invoice-paper {
        box-shadow: none;
        padding: 0;
    }

    .wrap {
        padding: 0;
    }

    .invoice-paper {
        margin: 0;
        width: calc(210mm - 16.18mm * 2);
        height: calc(297mm - 16.18mm * 2);
        box-shadow: initial;
    }

    .golden-wrapper,
    .golden {
        display: none;
    }

    .shadow-costs {
        display: none;
    }

    .foot-note {
        page-break-after: always;
    }

    .page-buttons {
        display: none;
    }
}